import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ mode }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/assets/js/header.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <header
      id="header"
      className={`site-header header_trans-fixed ${
        mode == "white-bg" ? "header-dark" : ""
      }`}
      data-top="992"
    >
      <div className="container">
        <div className="header-inner">
          <div className="toggle-menu">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          {/*   {/*  <!-- /.toggle-menu --> */}
          <div className="site-mobile-logo">
            <a href="/" className="logo">
              <img
                src={`/assets/img/${
                  mode == "white-bg" ? "sticky-logo.png" : "main-logo.png"
                }`}
                alt="site logo"
                className="main-logo"
              />
              <img
                src="/assets/img/sticky-logo.png"
                alt="site logo"
                className="sticky-logo"
              />
            </a>
          </div>
          <nav className={`site-nav ${mode == "white-bg" ? "nav-dark" : ""}`}>
            <div className="close-menu">
              <span>Close</span>
              <i className="ei ei-icon_close"></i>
            </div>

            <div className="site-logo">
              <a href="/" className="logo">
                <img
                  src={`/assets/img/${
                    mode == "white-bg" ? "sticky-logo.png" : "main-logo.png"
                  }`}
                  alt="site logo"
                  className="main-logo"
                />
                <img
                  src="/assets/img/sticky-logo.png"
                  alt="site logo"
                  className="sticky-logo"
                />
              </a>
            </div>
            {/*   {/*  <!-- /.site-logo --> */}

            <div className="menu-wrapper" data-top="992">
              <ul className="site-main-menu">
                <li>
                  <Link to="/" className="close-after-action">
                    Home
                  </Link>
                </li>
                <li>
                  <a href="#footer" className="close-after-action">
                    About
                  </a>
                </li>
                <li>
                  <Link to="/blog" className="close-after-action">
                    Blog
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    className="nav-btn close-after-action"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Contact
                  </a>
                </li>
              </ul>

              <div className="nav-right">
                <a
                  href="#"
                  className="nav-btn close-after-action"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Schedule a Demo
                </a>
              </div>
            </div>
            {/*   {/*  <!-- /.menu-wrapper --> */}
          </nav>
          {/*  <!-- /.site-nav --> */}
        </div>
        {/*  <!-- /.header-inner --> */}
      </div>
    </header>
  );
};

export default Navbar;
