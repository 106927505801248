import React from "react";

const Design = () => {
  return (
    <section className="editor-design">
      <div className="container">
        <div className="row">
          <div className="editure-feature-image wow pixFadeRight">
            <div className="image-one" data-parallax='{"x" : 30}'>
              <img
                src="media/feature/4.png"
                className="wow pixFadeRight"
                data-wow-delay="0.3s"
                alt="feature-image"
              />
            </div>
            <div className="image-two">
              <div className="image-two-inner" data-parallax='{"x" : -30}'>
                <img
                  src="media/feature/41.png"
                  className="wow pixFadeLeft"
                  data-wow-delay="0.5s"
                  alt="feature-image"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-6">
            <div className="editor-content">
              <div className="section-title style-two">
                <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                  Accessible from anywhere, anytime & any device.
                </h2>

                <p className="wow pixFadeUp" data-wow-delay="0.5s">
                  Web-based and Mobile Optimized UI & <br /> Robust Backend with
                  100% Uptime
                </p>
              </div>

              <div className="description wow pixFadeUp" data-wow-delay="0.7s">
                <p>
                  Our app is hosted in the cloud, and can be accessed from
                  anywhere. The user interface is optimized for both desktop and
                  mobile. Backend can be integrated with your database, or your
                  data can be stored securely in our cloud.
                </p>

                <a
                  href="/blog"
                  className="pix-btn wow pixFadeUp"
                  data-wow-delay="0.9s"
                >
                  Discover More
                </a>
              </div>
            </div>
            {/*  <!-- /.editor-content --> */}
          </div>
          {/*  <!-- /.col-lg-6 --> */}
        </div>
        {/*  <!-- /.row --> */}
      </div>
      {/*  <!-- /.container --> */}
      <div className="shape-bg">
        <img
          src="media/background/shape_bg.png"
          className="wow fadeInLeft"
          alt="shape-bg"
        />
      </div>
    </section>
  );
};

export default Design;
