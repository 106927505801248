import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <div className="footer-inner wow pixFadeUp">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="widget footer-widget">
              <h3 className="widget-title">About US</h3>

              <p>
                We are focused and committed to delivering tools and solutions
                to digitalize and automate the hospitality industry.
              </p>
            </div>
            {/*   {/*  <!-- /.widget footer-widget --> */}
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="widget footer-widget">
              <h3 className="widget-title">Contact Us</h3>
              <ul className="footer-menu">
                <li>
                  <b>Email: </b>sales@revsol.io
                </li>
                <li>
                  <b>Mobile: </b>+960 9664554
                </li>
              </ul>

              <ul className="footer-social-link">
                <li>
                  <a href="https://web.facebook.com/revsol.io" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/revsol_" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-google-plus-g" target="_blank"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/revsol"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
            {/*   {/*  <!-- /.widget footer-widget --> */}
          </div>
          {/*   {/*  <!-- /.col-lg-3 col-md-6 --> */}
        </div>
        {/*   {/*  <!-- /.row --> */}
      </div>
      {/*  <!-- /.footer-inner --> */}
      <div className="site-info">
        <div className="copyright">
          <p>© RevSol, 2021. All rights reserved.</p>
        </div>

        <ul className="site-info-menu">
          <li>
            <a href="#">Privacy & Policy.</a>
          </li>
          <li>
            <a href="#">Faq.</a>
          </li>
          <li>
            <a href="#">Terms.</a>
          </li>
        </ul>
      </div>
      {/*  <!-- /.site-info --> */}
    </div>
  );
};

export default Footer;
