import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { client } from "./client";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Moment from "moment";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//Import Components
import Loader from "./components/Loader";

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState(null);
  const [categories, setCategories] = useState(null);
  const [popularTags, setPopularTags] = useState(null);
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        console.log(node.data.target.fields.file.contentType);
        switch (node.data.target.fields.file.contentType) {
          case "image/png":
            return (
              <img
                src={`https:${node.data.target.fields.file.url}`}
                height={node.data.target.fields.file.details.image.height}
                width={node.data.target.fields.file.details.image.width}
              />
            );
          case "video/mp4":
            return (
              <div className="">
                <ReactPlayer
                  playing={true}
                  controls={true}
                  width="100%"
                  url={`https:${node.data.target.fields.file.url}`}
                />
              </div>
            );
          default:
            break;
        }
      },
    },
  };

  Moment.locale("en");

  const getLatestPosts = () => {
    setLoading(true);
    console.log("getLatestPosts");
    client
      .getEntries()
      .then((response) => {
        setBlogs(response.items);
        setLoading(false);
        // console.log(response.items);
      })
      .catch(console.error);
  };

  const getPopularTags = () => {
    setLoading(true);
    //console.log("getPopularTags");
    client
      .getTags()
      .then((response) => {
        setPopularTags(response.items);
        setLoading(false);
        //console.log("getPopularTags: ", response.items);
      })
      .catch(console.error);
  };

  const getPost = () => {
    setLoading(true);
    //console.log("getPost");
    client
      .getEntry(id)
      .then((response) => {
        setPost(response);
        setLoading(false);
        console.log("getPost: ", response);
      })
      .catch(console.error);
  };

  useEffect(() => {
    // console.log("Blog Page: ", post);
    post === null ? getPost() : post.sys.id != id && getPost();
    blogs === null && getLatestPosts();
    popularTags === null && getPopularTags();
  }, [id, blogs, popularTags, post]);

  if (loading || !blogs) return <Loader />;
  return (
    <div>
      <Helmet>
        {/* <meta property="og:url"                content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" /> */}
        <meta property="og:title" content={post.fields.title} />
        <meta
          property="og:image"
          content={post.fields.featured && post.fields.featured.fields.file.url}
        />
        <meta
          property="og:description"
          content={documentToHtmlString(post.fields.body, options)}
        />
      </Helmet>
      {/*  <!--==========================--> */}
      {/*  <!--=         Banner         =--> */}
      {/*  <!--==========================--> */}
      <section className="page-banner blog-details-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <ul className="post-meta color-theme">
              <li>
                <a href="#">
                  {Moment(post.sys.updatedAt).format("MMMM DD, YYYY")}
                </a>
              </li>
            </ul>
            <h1 className="page-title">{post.fields.title}</h1>

            <ul className="post-meta">
              <li>
                <span>By</span> <a href="#">{post.fields.author}</a>
              </li>

              <li>
                {post.metadata.tags.map((tag) => (
                  <a href="#" key={tag.sys.id}>
                    {tag.sys.id}
                    {Object.keys(post.metadata.tags).length > 1 && ", "}
                  </a>
                ))}
              </li>
            </ul>
          </div>
          {/*  <!-- /.page-title-wrapper --> */}
        </div>
        {/*  <!-- /.container --> */}

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(112, 181, 250)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>
      {/*  <!-- /.page-banner --> */}

      {/*  <!--========================--> */}
      {/*  <!--=         Blog         =--> */}
      {/*  <!--========================--> */}
      <section className="blog-single">
        <div className="container pb-120">
          <div className="row">
            <div className="col-md-8">
              <div className="post-wrapper">
                <article className="post post-signle">
                  <div className="feature-image">
                    <img
                      src={
                        post.fields.featured &&
                        post.fields.featured.fields.file.url
                      }
                      alt=""
                    />
                  </div>
                  <div className="blog-content">
                    {documentToReactComponents(post.fields.body, options)}

                    {/* <blockquote className="quote-post">
                      <p>
                        Horse play a blinding shot the little rotter nice one
                        umm I'm telling bits and bobs grub boot that bevvy,
                        cockup matie boy mush Jeffrey.!
                      </p>

                      <span className="quote-author">Druid Wensleydale</span>

                      <span className="quote">
                        <img src="/media/blog/quote.png" alt="" />
                      </span>
                    </blockquote> */}

                    <div className="tagcloud">
                      <span>Tags:</span>
                      {post.metadata.tags.map((tag) => (
                        <a href="#" key={tag.sys.id}>
                          {tag.sys.id}
                        </a>
                      ))}
                    </div>
                  </div>
                  {/*  <!-- /.post-content --> */}
                </article>
                {/*  <!-- /.post --> */}
                <div className="blog-share">
                  <div className="share-title">
                    <p>Share:</p>
                  </div>

                  <ul className="share-link">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/*  <!-- /.blog-share --> */}
              </div>
              {/*  <!-- /.post-wrapper --> */}
            </div>
            {/*  <!-- /.col-md-8 --> */}

            <div className="col-md-4">
              <div className="sidebar">
                {/* <div id="search" className="widget widget_search">
                  <form role="search" className="search-form-widget">
                    <label>
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search..."
                      />
                    </label>
                    <button type="submit" className="search-submit">
                      <i className="ei ei-icon_search"></i>
                    </button>
                  </form>
                </div> */}
                <div id="categories" className="widget widget_categories">
                  <h2 className="widget-title">Categories</h2>
                  <ul>
                    {blogs &&
                      blogs.map(
                        (blog) =>
                          blog.sys.contentType.sys.id ==
                            "revsolBlogPostCategories" && (
                            <li key={blog.sys.id}>
                              <a href="#">
                                {blog.fields.name}
                                <span className="count"></span>
                              </a>
                            </li>
                          )
                      )}
                  </ul>
                </div>
                <div id="gp-posts-widget-2" className="widget gp-posts-widget">
                  <h2 className="widget-title">Latest Posts</h2>
                  <div className="gp-posts-widget-wrapper">
                    {blogs &&
                      blogs.map(
                        (blog) =>
                          blog.sys.contentType.sys.id == "revsolBlog" &&
                          blog.sys.id != id && (
                            <div className="post-item" key={blog.sys.id}>
                              <div className="post-widget-thumbnail">
                                <a href="#">
                                  <img
                                    src={
                                      blog.fields.featured &&
                                      blog.fields.featured.fields.file.url
                                    }
                                    alt="thumb"
                                  />
                                </a>
                              </div>
                              <div className="post-widget-info">
                                <h5 className="post-widget-title">
                                  <Link to={`/blog/${blog.sys.id}`}>
                                    {blog.fields.title}
                                  </Link>
                                </h5>
                                <span className="post-date">
                                  {Moment(blog.sys.updatedAt).format(
                                    "MMMM DD, YYYY, dddd"
                                  )}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                  </div>
                </div>

                <aside id="tags" className="widget widget_tag">
                  <h3 className="widget-title">Popular Tags</h3>
                  <div className="tagcloud">
                    {popularTags &&
                      popularTags.map((tag) => (
                        <a key={tag.sys.id} href="#">
                          {tag.name}
                        </a>
                      ))}
                  </div>
                </aside>
                {/*  <!-- /.widget --> */}
              </div>
              {/*  <!-- /.sidebar --> */}
            </div>
            {/*  <!-- /.col-md-4 --> */}
          </div>
          {/*  <!-- /.row --> */}
        </div>
        {/*  <!-- /.container --> */}
      </section>
      {/*  <!-- /.blog-single --> */}
    </div>
  );
};

export default Post;
