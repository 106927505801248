import React, { useEffect, useState } from "react";
import Moment from "moment";
import { client } from "./client";

//Import Components
import Loader from "./components/Loader";
import Posts from "./components/Posts";

const Blog = ({}) => {
  const [blogs, setBlogs] = useState(null);
  const [loading, setLoading] = useState(true);

  Moment.locale("en");

  useEffect(() => {
    setLoading(true);
    console.log("Blog Page");
    client
      .getEntries()
      .then((response) => {
        setBlogs(response.items);
        setLoading(false);
        //console.log(response.items);
      })
      .catch(console.error);
  }, []);

  if (loading) return <Loader />;
  return (
    <div>
      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper wow pixFadeUp">
            <h1 className="page-title">The RevSol Blog</h1>
            <ul className="bradcurmed">
              <li>
                <a href="#" rel="noopener noreferrer">
                  Home
                </a>
              </li>
              <li>The RevSol Blog</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(112, 181, 250)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <div className="blog-post-archive">
        <div className="container">
          <div className="row">
            {blogs.map(
              (blog) =>
                blog.sys.contentType.sys.id == "revsolBlog" && (
                  <Posts
                    id={blog.sys.id}
                    key={blog.sys.id}
                    title={blog.fields.title}
                    featured={
                      blog.fields.featured &&
                      blog.fields.featured.fields.file.url
                    }
                    author={blog.fields.author}
                    publishedDate={Moment(blog.sys.updatedAt).format(
                      "MMM DD, YYYY"
                    )}
                  />
                )
            )}
          </div>

          <ul className="post-navigation">
            <li className="active">1</li>
            {/* <li>
              <a href="#">2</a>
            </li> */}
            <li className="next">
              <a href="#">
                <i className="ei ei-arrow_carrot-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Blog;
