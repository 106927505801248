import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";

import Loader from "./Loader";

const Booking = ({ title }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [travelAgency, setTravelAgency] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState("");
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    //https://revsol.atlassian.net/rest/api/3/issue/RS-1

    return () => {};
  }, []);

  const onSubmit = () => {
    //  "https://revsol-website-api.revsol-website.workers.dev/schedule-demo",
    setLoading(true);
    setBookingStatus("");
    fetch(
      "https://revsol-website-api.revsol-website.workers.dev/schedule-demo",
      {
        body: JSON.stringify({
          travelAgency: travelAgency,
          email: email,
          contact: contact,
        }),
        method: "POST",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        data.success == "true"
          ? setBookingStatus("success")
          : setBookingStatus("failed");
        console.log(data);
      })
      .catch(() => {
        setLoading(false);
        setBookingStatus("failed");
      });
  };
  return (
    <div
      className="modal fade"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading && bookingStatus != "success" ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <form>
                <div className="form-group">
                  <label htmlFor="agency">Travel Agency Name</label>
                  <input
                    {...register("agency", { required: true })}
                    type="agency"
                    className="form-control"
                    id="agency"
                    placeholder=""
                    value={travelAgency}
                    onChange={(e) => setTravelAgency(e.target.value)}
                  />
                  {errors.agency && (
                    <span className="badge badge-pill badge-danger">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    className="form-control"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name@example.com"
                  />
                  {errors.email && (
                    <span className="badge badge-pill badge-danger">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Contact Number</label>
                  <input
                    {...register("phone", { required: true })}
                    type="phone"
                    className="form-control"
                    id="phone"
                    required
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    placeholder="+960 9000000"
                  />
                  {errors.phone && (
                    <span className="badge badge-pill badge-danger">
                      This field is required
                    </span>
                  )}
                </div>
              </form>
            )}
            {bookingStatus == "success" && (
              <div className="alert alert-success" role="alert">
                We have received your request. One of our agent will contact you
                soon!
              </div>
            )}
            {bookingStatus == "failed" && (
              <div className="alert alert-danger" role="alert">
                We could not process your request! Please contact us on 9621737
              </div>
            )}
          </div>
          <div className="modal-footer">
            <a
              type="button"
              className="pix-btn"
              style={{ backgroundColor: "grey" }}
              data-dismiss="modal"
            >
              Close
            </a>
            {bookingStatus != "success" && (
              <a
                type="button"
                className="pix-btn "
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
