import React from "react";

const Offers = () => {
  return (
    <section className="genera-informes">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pix-order-one">
            <div className="section-title style-two">
              <h2 className="title wow pixFadeUp">
                Tactical Offer creation and offer Combinations
              </h2>

              <p className="wow pixFadeUp" data-wow-delay="0.3s">
                Create multiple offers with different combinations with hundreds
                of customizable options
              </p>
            </div>
            {/*  <!-- /.section-title style-two --> */}
            <ul className="list-items wow pixFadeUp" data-wow-delay="0.4s">
              <li>Offers for Room, meal plans and transfers</li>
              <li>Offer for Multiple Markets</li>
              <li>Combine Tactical and Contract Offers</li>
            </ul>
            <a
              href="/blog"
              className="pix-btn btn-outline wow pixFadeUp"
              data-wow-delay="0.5s"
            >
              Discover More
            </a>
          </div>
          {/*  <!-- /.col-lg-6 --> */}
          <div className="informes-feature-image">
            <div className="image-one" data-parallax='{"y" : 20}'>
              <img
                src="media/feature/5.png"
                className="wow pixFadeDown"
                alt="informes"
              />
            </div>

            <div className="image-two" data-parallax='{"y" : -20}'>
              <img
                src="media/feature/51.png"
                className=" mw-none wow pixFadeDown"
                data-wow-delay="0.3s"
                alt="informes"
              />
            </div>
          </div>
        </div>
        {/*  <!-- /.row --> */}
      </div>
      {/*  <!-- /.container --> */}
      <div className="shape-bg">
        <img
          src="media/background/shape.png"
          className="wow fadeInRight"
          alt="shape-bg"
        />
      </div>
    </section>
  );
};

export default Offers;
