import React, { useEffect } from "react";

const Screens = () => {
  useEffect(() => {
    const script = document.createElement("script");
    // <script src="/assets/js/app.js"></script>
    script.src = "/assets/js/pix-tabs.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <section className="revolutionize">
      <div className="bg-angle"></div>
      <div className="container">
        <div className="section-title dark-title text-center">
          <h3 className="sub-title wow pixFadeUp">App Screens</h3>
          <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
            Revolutionize your travel Agency
            <br />
            with RevSol
          </h2>
        </div>
        {/*  <!-- /.section-title dark-title --> */}
        <div id="pix-tabs" className="wow pixFadeUp" data-wow-delay="0.5s">
          <ul id="pix-tabs-nav">
            <li>
              <a href="#tab1">Sales Managment</a>
            </li>
            <li>
              <a href="#tab2">Dashboard</a>
            </li>
            <li>
              <a href="#tab3">Property Managment</a>
            </li>
            <li>
              <a href="#tab4">Customize Everything</a>
            </li>
            <li>
              <a href="#tab5">Enquiry Processing</a>
            </li>
          </ul>
          {/*   {/*  <!-- tabs-nav --> */}
          <div id="pix-tabs-content">
            <div id="tab1" className="content">
              <img src="media/revolutionize/2.png" alt="revolutionize" />

              <div className="shape-shadow"></div>
            </div>
            <div id="tab2" className="content">
              <img src="media/revolutionize/1.png" alt="revolutionize" />
              <div className="shape-shadow"></div>
            </div>
            <div id="tab3" className="content">
              <img src="media/revolutionize/3.png" alt="revolutionize" />
              <div className="shape-shadow"></div>
            </div>

            <div id="tab4" className="content">
              <img src="media/revolutionize/4.png" alt="revolutionize" />
              <div className="shape-shadow"></div>
            </div>
            <div id="tab5" className="content">
              <img src="media/revolutionize/5.png" alt="revolutionize" />
              <div className="shape-shadow"></div>
            </div>
          </div>
          {/*   {/*  <!-- tabs-content --> */}
        </div>
        {/*   {/*  <!-- Tabs --> */}
      </div>
      {/*  <!-- /.container --> */}
    </section>
  );
};

export default Screens;
