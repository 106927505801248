import React from "react";

const Banner = () => {
  return (
    <section className="banner banner-one">
      <div className="circle-shape" data-parallax='{"y" : 230}'>
        <img src="/media/banner/circle-shape.png" alt="circle" />
      </div>
      <div className="container">
        <div className="banner-content-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-content">
                <h1
                  className="banner-title wow pixFadeUp"
                  data-wow-delay="0.3s"
                >
                  Revsol TAMS - E2E Management <br />
                  Solution for
                  <br />
                  <span> Travel Agencies</span>
                </h1>

                <p className="description wow pixFadeUp" data-wow-delay="0.5s">
                  Generate & send a quotation to your client in less than 5
                  minutes.
                </p>

                <a
                  href="#"
                  className="pxs-btn banner-btn wow pixFadeUp"
                  data-wow-delay="0.6s"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Free Trial
                </a>
              </div>
              {/*  <!-- /.banner-content --> */}
            </div>
            {/*  <!-- /.col-lg-6 --> */}
            <div className="col-lg-6">
              <div className="promo-mockup wow pixFadeLeft">
                <img src="/media/banner/macbook.png" alt="mpckup" />
              </div>
              {/*  <!-- /.promo-mockup --> */}
            </div>
            {/*  <!-- /.col-lg-6 --> */}
          </div>
          {/*  <!-- /.row --> */}
        </div>
        {/*  <!-- /.banner-content-wrap --> */}
      </div>
      {/*  <!-- /.container --> */}
      <div className="bg-shape">
        <img src="/media/banner/shape-bg.png" alt="" />
      </div>
    </section>
  );
};

export default Banner;
