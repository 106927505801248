import React from "react";

//Import Components
import Banner from "./components/Banner";
import Featured from "./components/Featured";
import Design from "./components/Design";
import Offers from "./components/Offers";
import Screens from "./components/Screens";
import CalltoAction from "./components/CalltoAction";

const Home = () => {
  return (
    <>
      <Banner />
      <Featured />
      <Design />
      <Offers />
      <Screens />
      <CalltoAction />
    </>
  );
};

export default Home;
