import React from "react";

const Posts = ({ id, title, author, publishedDate, featured }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <article className="post-post-grid">
        <div className="feature-image">
          <a href={`/blog/${id}`}>
            <img src={featured} alt="" />
          </a>
        </div>
        <div className="blog-content">
          <ul className="post-meta">
            <li>
              <a href="#">{publishedDate}</a>
            </li>
          </ul>

          <h3 className="entry-title">
            <a href={`/blog/${id}`}>{title}</a>
          </h3>

          <div className="author">
            {/* <img src="/media/blog/auth2.jpg" alt="author" /> */}
            <a href="#" className="author-link">
              {author}
            </a>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Posts;
