import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

//Import Components
import Navbar from "./components/Navbar";
import Loader from "./components/Loader";
import Footer from "./components/Footer";
import Booking from "./components/Booking";
import Home from "./Home";
import Blog from "./Blog";
import Post from "./Post";

function App() {
  return (
    <Router>
      <div id="home-version-1" className="home-version-4" data-style="default">
        <Booking />
        <a
          href="#main_content"
          data-type="section-switch"
          className="return-to-top"
        >
          <i className="fa fa-chevron-up"></i>
        </a>
        <Loader />
        <div id="main_content">
          <Switch>
            <Route exact path="/">
              <Navbar mode="dark-bg" />
              <Home />
            </Route>
            <Route exact path="/blog">
              <Navbar mode="white-bg" />
              <Blog />
            </Route>
            <Route path="/contact">
              <Navbar mode="white-bg" />
              <div>deded</div>
            </Route>
            <Route path="/blog/:id">
              <Navbar mode="white-bg" />
              <Post />
            </Route>
          </Switch>
          <footer id="footer">
            <Footer />
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;
