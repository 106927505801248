import React from "react";

const CalltoAction = () => {
  return (
    <section className="call-to-action">
      <div className="overlay-bg">
        <img src="media/background/ellipse.png" alt="bg" />
      </div>
      <div className="container">
        <div className="action-content text-center wow pixFadeUp">
          <h2 className="title">
            We are optimists who
            <br />
            love to work together
          </h2>

          <p>
            We are focused and committed to delivering tools and <br />
            solutions to digitalize and automate the hospitality industry.
          </p>

          <a
            href="#"
            className="pix-btn btn-light"
            data-toggle="modal"
            data-target="#exampleModalCenter"
          >
            Free Consultation
          </a>
        </div>
        {/*   {/*  <!-- /.action-content --> */}
      </div>
      {/*   {/*  <!-- /.container --> */}

      <div className="scroll-circle">
        <img
          src="media/background/circle13.png"
          data-parallax='{"y" : -130}'
          alt="circle"
        />
      </div>
      {/*   {/*  <!-- /.scroll-circle --> */}
    </section>
  );
};

export default CalltoAction;
