import React from "react";

const Featured = () => {
  return (
    <section className="featured">
      <div className="container">
        <div className="section-title text-center wow pixFade">
          <h3 className="sub-title">Working Process</h3>
          <h2 className="title">The only app you will need</h2>
        </div>
        {/*  <!-- /.section-title --> */}
        <div className="row">
          <div className="col-md-4">
            <div
              className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
              data-wow-delay="0.3s"
            >
              <div className="saaspik-icon-box-icon">
                <img
                  src="media/feature/1.svg"
                  alt=""
                  height="180px"
                  width="180px"
                />
              </div>
              <div className="pixsass-icon-box-content">
                <h3 className="pixsass-icon-box-title">
                  <a href="#">
                    Maintain resorts or <br /> guest house inventory
                  </a>
                </h3>
              </div>
            </div>
            {/*  <!-- /.pixsass-box style-one --> */}
          </div>
          {/*  <!-- /.col-md-4 --> */}
          <div className="col-md-4">
            <div
              className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
              data-wow-delay="0.5s"
            >
              <div className="saaspik-icon-box-icon">
                <img
                  src="media/feature/2.svg"
                  alt=""
                  height="180px"
                  width="180px"
                />
              </div>
              <div className="pixsass-icon-box-content">
                <h3 className="pixsass-icon-box-title">
                  <a href="#">
                    Manage your contracts
                    <br /> in one place
                  </a>
                </h3>
              </div>
            </div>
            {/*  <!-- /.pixsass-box style-one --> */}
          </div>
          {/*  <!-- /.col-md-4 --> */}
          <div className="col-md-4">
            <div
              className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
              data-wow-delay="0.7s"
            >
              <div className="saaspik-icon-box-icon">
                <img
                  src="media/feature/3.svg"
                  alt=""
                  height="220px"
                  width="220px"
                />
              </div>
              <div className="pixsass-icon-box-content">
                <h3 className="pixsass-icon-box-title">
                  <a href="#">
                    End-to-End Inquiry <br /> & Sales Managment
                  </a>
                </h3>
              </div>
            </div>
            {/*  <!-- /.pixsass-box style-one --> */}
          </div>
          {/*  <!-- /.col-md-4 --> */}
        </div>
        {/*  <!-- /.row --> */}
      </div>
      {/*  <!-- /.container --> */}
    </section>
  );
};

export default Featured;
